import { Controller } from '@hotwired/stimulus';
import { FrameElement as HTMLTurboFrameElement } from '@hotwired/turbo';

/**
 * Reloads a turbo-frame so long as the "enabled" target is present within the frame.
 */
export default class ReloadFrameController extends Controller<HTMLTurboFrameElement> {
  private declare readonly srcValue: string;
  private declare readonly hasEnabledTarget: boolean;

  public static targets = ['enabled'];

  public static values = { src: String };

  /**
   * This element will get recreated everytime the frame is reloaded,
   * if this controller should remain enabled.
   */
  public enabledTargetConnected(): void {
    // ensure the frames source is set
    this.element.src ||= this.srcValue;

    setTimeout(() => {
      this.element.reload().catch(console.error);
    }, 1000);
  }
}
