import { Controller } from '@hotwired/stimulus';

interface EndOfLifeDateProductCycle {
  cycle: string;
  releaseDate: string;
  eol: string | false;
  latest: string;
  latestReleaseDate: string;
  lts: boolean;

  support?: boolean | string;
  extendedSupport?: boolean;

  releaseLabel?: string;
  supportedPHPVersions?: string;
  link?: string;
  codename?: string;
}

/**
 * Manages the form for creating and editing application tools, which requires
 * populating a select with a list of options based on the option selected in
 * another select
 */
export default class ApplicationToolFormController extends Controller<HTMLElement> {
  public static targets = ['toolSelector', 'cycleSelector'];

  public declare readonly toolSelectorTarget: HTMLSelectElement;
  public declare readonly cycleSelectorTarget: HTMLSelectElement;

  private _abortController = new AbortController();

  public toolSelectorTargetConnected(element: HTMLSelectElement): void {
    element.addEventListener('change', () => {
      this._abortPreviousRequests();
      this._updateCycleSelectOptions(element.value).catch(console.error);
    });
  }

  /**
   * Aborts any external requests that might be in flight
   *
   * @private
   */
  private _abortPreviousRequests() {
    this._abortController.abort();
    this._abortController = new AbortController();
  }

  /**
   * Updates the options available in the `<select>` based on the cycles for
   * the given `product`
   *
   * @param product
   *
   * @private
   */
  private async _updateCycleSelectOptions(product: string) {
    this.cycleSelectorTarget.disabled = true;

    while (this.cycleSelectorTarget.options.length > 0) {
      this.cycleSelectorTarget.options.remove(0);
    }

    if (product === '') {
      return;
    }

    const cycles = await this._fetchCycles(product);

    // bail out if the selected tool has since changed
    if (this.toolSelectorTarget.value !== product) {
      return;
    }

    for (const cycle of cycles) {
      this._addCycleAsOption(cycle);
    }

    this.cycleSelectorTarget.disabled = false;
  }

  /**
   * Adds the given cycle as an `<option>` to the cycle `<select>` field
   *
   * @param cycle
   *
   * @private
   */
  private _addCycleAsOption(cycle: EndOfLifeDateProductCycle) {
    const option = document.createElement('option');

    option.text = cycle.cycle;

    this.cycleSelectorTarget.options.add(option);
  }

  /**
   * Fetches the cycles for the given `product` from https://endoflife.date
   *
   * @param product
   *
   * @private
   */
  private async _fetchCycles(product: string) {
    const response = await fetch(`https://endoflife.date/api/${product}.json`, {
      signal: this._abortController.signal
    });

    if (response.status !== 200) {
      throw new Error(
        `unexpected response: ${response.status} ${response.statusText}`
      );
    }

    return response.json() as Promise<EndOfLifeDateProductCycle[]>;
  }
}
