import { Controller } from '@hotwired/stimulus';

/**
 * Selects the connected element on click
 */
export default class SelectOnClickController extends Controller<HTMLInputElement> {
  public connect(): void {
    this.element.addEventListener('click', () => {
      this.element.select();
    });
  }
}
